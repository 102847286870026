import { default as accountIWnKuvcAuMMeta } from "/app/pages/account.vue?macro=true";
import { default as affiliateeUW8efOfkYMeta } from "/app/pages/affiliate.vue?macro=true";
import { default as ai_45nudeJr4wMvesqzMeta } from "/app/pages/ai-nude.vue?macro=true";
import { default as _91name_93QtbZDloVnTMeta } from "/app/pages/blog/[name].vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as contactV6oPJjs9RyMeta } from "/app/pages/contact.vue?macro=true";
import { default as createyuywxUUkQlMeta } from "/app/pages/create.vue?macro=true";
import { default as deepnudegKR5mGH7byMeta } from "/app/pages/deepnude.vue?macro=true";
import { default as edit_45uploadZtX5uFniyvMeta } from "/app/pages/edit-upload.vue?macro=true";
import { default as editm3OgXtuFe4Meta } from "/app/pages/edit.vue?macro=true";
import { default as exploreRKECn8OWt4Meta } from "/app/pages/explore.vue?macro=true";
import { default as _91id_932mTdwVeq8vMeta } from "/app/pages/image-history/collections/[id].vue?macro=true";
import { default as indexGWWuF0nXk4Meta } from "/app/pages/image-history/collections/index.vue?macro=true";
import { default as indexlbbX2L2EAQMeta } from "/app/pages/image-history/index.vue?macro=true";
import { default as _91id_93MroBuyXzhEMeta } from "/app/pages/image-history/public-collection/[id].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as nsfw_45aiCFRIuc1AazMeta } from "/app/pages/nsfw-ai.vue?macro=true";
import { default as indexYgRHYxGzjsMeta } from "/app/pages/password-reset/index.vue?macro=true";
import { default as newLjAJoh5oRtMeta } from "/app/pages/password-reset/new.vue?macro=true";
import { default as policyhDmBrXN4qOMeta } from "/app/pages/policy.vue?macro=true";
import { default as index6jWfsvUeybMeta } from "/app/pages/porn/index.vue?macro=true";
import { default as pricingpTmAIboEh0Meta } from "/app/pages/pricing.vue?macro=true";
import { default as prompt_45preferencesaOPfSMCjseMeta } from "/app/pages/prompt-preferences.vue?macro=true";
import { default as sexy_45aiTOx4r77BI0Meta } from "/app/pages/sexy-ai.vue?macro=true";
import { default as signupezBbZGXKU6Meta } from "/app/pages/signup.vue?macro=true";
import { default as success0bZk2aWKHZMeta } from "/app/pages/success.vue?macro=true";
import { default as termsy18ZESLqu6Meta } from "/app/pages/terms.vue?macro=true";
import { default as undress_45aijN5cEXmG0CMeta } from "/app/pages/undress-ai.vue?macro=true";
import { default as verifyuQf3dnBI8DMeta } from "/app/pages/verify.vue?macro=true";
export default [
  {
    name: "account",
    path: "/account",
    meta: accountIWnKuvcAuMMeta || {},
    component: () => import("/app/pages/account.vue").then(m => m.default || m)
  },
  {
    name: "affiliate",
    path: "/affiliate",
    meta: affiliateeUW8efOfkYMeta || {},
    component: () => import("/app/pages/affiliate.vue").then(m => m.default || m)
  },
  {
    name: "ai-nude",
    path: "/ai-nude",
    meta: ai_45nudeJr4wMvesqzMeta || {},
    component: () => import("/app/pages/ai-nude.vue").then(m => m.default || m)
  },
  {
    name: "SinglBlock",
    path: "/blog/:name()",
    meta: _91name_93QtbZDloVnTMeta || {},
    component: () => import("/app/pages/blog/[name].vue").then(m => m.default || m)
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: "Contact",
    path: "/contact",
    meta: contactV6oPJjs9RyMeta || {},
    component: () => import("/app/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: "Create",
    path: "/create",
    meta: createyuywxUUkQlMeta || {},
    component: () => import("/app/pages/create.vue").then(m => m.default || m)
  },
  {
    name: "deepnude",
    path: "/deepnude",
    meta: deepnudegKR5mGH7byMeta || {},
    component: () => import("/app/pages/deepnude.vue").then(m => m.default || m)
  },
  {
    name: "EditUpload",
    path: "/edit-upload",
    meta: edit_45uploadZtX5uFniyvMeta || {},
    component: () => import("/app/pages/edit-upload.vue").then(m => m.default || m)
  },
  {
    name: "Edit",
    path: "/edit",
    meta: editm3OgXtuFe4Meta || {},
    component: () => import("/app/pages/edit.vue").then(m => m.default || m)
  },
  {
    name: "Explore",
    path: "/explore",
    meta: exploreRKECn8OWt4Meta || {},
    component: () => import("/app/pages/explore.vue").then(m => m.default || m)
  },
  {
    name: "image-history-collections-id",
    path: "/image-history/collections/:id()",
    meta: _91id_932mTdwVeq8vMeta || {},
    component: () => import("/app/pages/image-history/collections/[id].vue").then(m => m.default || m)
  },
  {
    name: "image-history-collections",
    path: "/image-history/collections",
    meta: indexGWWuF0nXk4Meta || {},
    component: () => import("/app/pages/image-history/collections/index.vue").then(m => m.default || m)
  },
  {
    name: "image-history",
    path: "/image-history",
    meta: indexlbbX2L2EAQMeta || {},
    component: () => import("/app/pages/image-history/index.vue").then(m => m.default || m)
  },
  {
    name: "image-history-public-collection-id",
    path: "/image-history/public-collection/:id()",
    meta: _91id_93MroBuyXzhEMeta || {},
    component: () => import("/app/pages/image-history/public-collection/[id].vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "nsfw-ai",
    path: "/nsfw-ai",
    meta: nsfw_45aiCFRIuc1AazMeta || {},
    component: () => import("/app/pages/nsfw-ai.vue").then(m => m.default || m)
  },
  {
    name: "password-reset",
    path: "/password-reset",
    meta: indexYgRHYxGzjsMeta || {},
    component: () => import("/app/pages/password-reset/index.vue").then(m => m.default || m)
  },
  {
    name: "password-reset-new",
    path: "/password-reset/new",
    meta: newLjAJoh5oRtMeta || {},
    component: () => import("/app/pages/password-reset/new.vue").then(m => m.default || m)
  },
  {
    name: "Policy",
    path: "/policy",
    meta: policyhDmBrXN4qOMeta || {},
    component: () => import("/app/pages/policy.vue").then(m => m.default || m)
  },
  {
    name: "porn",
    path: "/porn",
    meta: index6jWfsvUeybMeta || {},
    component: () => import("/app/pages/porn/index.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    meta: pricingpTmAIboEh0Meta || {},
    component: () => import("/app/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "prompt-preferences",
    path: "/prompt-preferences",
    meta: prompt_45preferencesaOPfSMCjseMeta || {},
    component: () => import("/app/pages/prompt-preferences.vue").then(m => m.default || m)
  },
  {
    name: "sexy-ai",
    path: "/sexy-ai",
    meta: sexy_45aiTOx4r77BI0Meta || {},
    component: () => import("/app/pages/sexy-ai.vue").then(m => m.default || m)
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupezBbZGXKU6Meta || {},
    component: () => import("/app/pages/signup.vue").then(m => m.default || m)
  },
  {
    name: "Success",
    path: "/success",
    meta: success0bZk2aWKHZMeta || {},
    component: () => import("/app/pages/success.vue").then(m => m.default || m)
  },
  {
    name: "Terms",
    path: "/terms",
    meta: termsy18ZESLqu6Meta || {},
    component: () => import("/app/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: "undress-ai",
    path: "/undress-ai",
    meta: undress_45aijN5cEXmG0CMeta || {},
    component: () => import("/app/pages/undress-ai.vue").then(m => m.default || m)
  },
  {
    name: "Verify",
    path: "/verify",
    meta: verifyuQf3dnBI8DMeta || {},
    component: () => import("/app/pages/verify.vue").then(m => m.default || m)
  }
]